import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import { Notification, MessageTypes } from "@/interfaces/proccess";
import OverView from '@/views/Admin/SegmentsCategories/Create/overview.vue';
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({

    name: "CreateSegmentCategory",

    props: {},

    components: { Alertize, OverView },

    data: () => ({
        title: "CreateSegmentCategory",
    }),

    created() {},

    mounted() {},

    computed: {},

    methods: {

        getID(): Number {
			return Number(this.$route.params.id);
		},

		hasID() {
			return !!this.$route.params.id;
		},

        getSegmentIDRoute(){
            return this.$route.query.segment_id
        },

        getAdvertiserIDRoute(){
            return this.$route.query.advertiser_id
        },

		isLoading() {
			return this.$store.state.proccess.loading;
		},

        setNotification(notification: Notification) {
            return this.$store.dispatch("proccess/setNotification", notification, {
                root: true
            });
        },

        redirectTo() {
            this.setNotification({ title: "", message: "", type: "" });
            this.$router.push({ name: "SegmentsIndex" });
        },

        getMsgSuccess(redirec: boolean, to: string, message: string) {
			let msgSucces = {
				title: "Success",
                message: message,
				type: MessageTypes.SUCCESS,
				show: true,
                details: "",
                to: redirec ? to: undefined,
				btn_text: "Close"
			};
			return msgSucces;
		},

        async getSegmentCategoriesByID(id: Number){
            return await this.dispachSegmentCategories();
        },

        async setLoadingField(loading: boolean = false) {
			return this.$store.dispatch("proccess/setLoadingField", loading);
		},

        /*
        * Metodos Fetch
        */

        async fetchAdvertiser(){
            try {
                await this.setLoadingField(true);
                await this.dispatchAdvertisers();
                await this.setLoadingField(false);
            } catch (error) {
                await this.setLoadingField(false);
            }
        },

        /*
        * Metodos Dispatch
        */

        async dispatchAdvertisers() {
            return this.$store.dispatch("advertiser/list", {
                filters: { active: true },
                options: { sort: "name", order: "asc" }
            });
        },

        async dispachSegmentCategories(id: Number) {
            return this.$store.dispatch("segment_category/show", id);
        },

    }

});